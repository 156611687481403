<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- USER ROLE LIST -->
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center form-title">
          <h4 class="mb-0">COUNTRY LIST</h4>
          <div class="flex flex-wrap justify-end items-center">
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                placeholder="Search..."
              />
              <!-- <vs-button
                class="mb-4 md:mb-0 text-hover-white"
                @click="gridApi.exportDataAsCsv()"
                >Export as CSV</vs-button -->

            </div>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowClicked="onRowDataChanged"
          :rowData="countries"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
        >
        </ag-grid-vue>
        <!-- :pagination="true"
          :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"-->
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import contacts from "../../ui-elements/ag-grid-table/data.json";
import DatetimePickerStartEnd from "@/views/components/extra-components/datetime-picker/DatetimePickerStartEnd.vue";
const VxTour = () => import("@/components/VxTour.vue");
import CountryService from "@/services/countryService.js";
import countryStatus from "./countryStatus";
import editCountry from "./editCountry.vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import SearchService from "@/services/searchService.js";

export default {
  components: {
    AgGridVue,
    VxTour,
    DatetimePickerStartEnd,
    "switch-component": {
      template: `<span>sjd</span>`
    }
  },
  data() {
    return {
      countries: [],
      User: "Select User",
      switchValue: true,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null
      },
      totalPages: 0,
      configTodateTimePicker: {
        minDate: null
      },
      cityOptions: [
        { text: "New York", value: "new-york" },
        { text: "Chicago", value: "chicago" },
        { text: "San Francisco", value: "san-francisco" },
        { text: "Boston", value: "boston" }
      ],
      contacts: contacts,
      columnDefs: []
    };
  },

  beforeMount() {
    eventBus.$on("refreshCountryTable", e => {
      this.getAllCountryList(this.currentPage);
    });
    this.columnDefs = [
      // {
      //   headerName: "Sr.No.",
      //   field: "firstname",
      //   width: 150,
      //   filter: true,
      //   checkboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   headerCheckboxSelection: true
      // },
      {
        headerName: "Country Name",
        field: "country_name",
        filter: true,
        width: 575
      },
      {
        headerName: "Country Code",
        field: "country_code",
        filter: true,
        width: 250,
        height: 100
      },

      // {
      //   headerName: "Edit",
      //   field: "country_id",
      //   filter: false,
      //   minWidth: 80,
      //   maxWidth: 80,
      //   hide: this.archive == "archive" ? true : false,
      //   cellStyle: { "text-align": "center" },
      //   cellRendererFramework: editCountry
      // },
      {
        headerName: "Status",
        field: "country_active",
        filter: false,
        width: 150,
        hide: this.archive == "archive" ? true : false,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: countryStatus
      }
      // {
      //   headerName: this.archive == "archive" ? "Unarchive" : "Archive",
      //   field: "country_id",
      //   filter: false,
      //   minWidth: 80,
      //   maxWidth:80,
      //   cellStyle: { "text-align": "center" },
      //   cellRenderer: function(params) {
      //     return '<span class="text-center table-edit-icon"><i class="text-center material-icons">archive</i></span>';
      //   }
      // }
    ];
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    // totalPages() {
    //   if (this.gridApi) return this.gridApi.paginationGetTotalPages();
    //   else return 0;
    // },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          this.getAllCountryList(val);
          return val;
        } else return 1;
      }
    }
  },
  methods: {
    onRowDataChanged(e) {
      console.log("e :", e);
    },
    onChangeStatus: function() {
      console.log("object");
    },
    updateSearchQuery(val) {
      SearchService.getAllSerchResult(val, "country", null)
        .then(response => {
          const { data } = response;
          if (data.data.length > 0 && data.Status) {
            this.countries = data.data;
            this.totalPages = data.pagination.last_page;
          } else {
            this.countries =[]
            // this.$vs.notify({
            //   title: "Search data not found",
            //   text: data.Message,
            //   iconPack: "feather",
            //   icon: "check_box",
            //   color: "warning"
            // });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Data Not Found",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning"
          });
        });
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    getAllCountryList: function(currentPage) {
      let payload = {
        page: currentPage
      };
      CountryService.getAllCountry(payload)
        .then(response => {
          const { data } = response;
          if (!data.err || data.Status == true) {
            this.countries = data.data;
            this.totalPages = data.pagination.last_page;
            this.gridApi.sizeColumnsToFit();
          } else {
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.getAllCountryList(this.currentPage);
    this.gridApi = this.gridOptions.api;
  }
};
</script>
