<template>
  <div>
    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="2"
      v-model="form.country_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'grid' + params.data.country_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Country Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import CountryService from "@/services/countryService.js";
export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        country_active: "1",
      },
      copy_country_active: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    this.copy_country_active = this.form.country_active;
  },
  beforeDestroy() {
    let _id = document.getElementById("grid" + this.params.data.country_id);
    // console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function (e, value) {
      this.$vs.loading();
      let id = this.params.data.country_id;

      let payload = {
        country_id: this.form.country_id,
        organization_id: this.form.organization_id,
        country_active: this.form.country_active,
      };
      CountryService.editCountry(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          console.log(data);
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            // eventBus.$emit("refreshTable", data);
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.privStatus();
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.privStatus();
        });
    },
    privStatus: function () {
      this.popupActive = false;
      this.form.country_active = this.copy_country_active;
    },
  },
});
</script>
